import React from "react"
import HeaderMain from "./partials/navigation/HeaderMain"
import FooterMain from "./partials/navigation/FooterMain"
import FloatingActionButton from "./partials/FloatingActionButton"
import externalScripts from "./../modules/ExternalScripts"
import klaro from "./../modules/Klaro"

const Layout = props => {
  externalScripts()
  klaro()

  return (
    <div>
      <HeaderMain />
      <main>{props.children}</main>
      <FooterMain />
      <FloatingActionButton />
    </div>
  )
}

export default Layout
