import React from "react"
import Transition from "../../../../transistion/Transition"
import { Link } from "gatsby"

import Logo from "./../../../../../data/img/logo.svg"

const MobileMenu = props => {
  const mainLinks = props.mainLinks.map((link, i) => {
    return (
      <Link
        key={i}
        to={link.link}
        className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-100 transition ease-in-out duration-150"
      >
        {link.linkSvg}
        <div className="text-base leading-6 font-medium text-gray-900">
          {link.linkName}
        </div>
      </Link>
    )
  })

  return (
    <Transition
      show={props.state}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-20">
        <div className="rounded-lg shadow-lg">
          <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5 space-y-6">
              <div className="flex items-center justify-between">
                <div>
                  <Link to="/">
                    <img
                      className="h-7 w-auto"
                      src={Logo}
                      alt="Florian Dierda Logo"
                    />
                  </Link>
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    onClick={props.toggleMenu}
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <nav className="grid gap-8">{mainLinks}</nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="space-y-6">
                <span className="w-full flex rounded-md shadow-sm">
                  <a
                    href={props.ctaLinkEnquiry}
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                  >
                    {props.ctaLinkEnquiryName}
                  </a>
                </span>
                <p className="text-center text-base leading-6 font-medium text-gray-500">
                  Fragen?{" "}
                  <a
                    href={props.ctaLinkContact}
                    className="text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                  >
                    {props.ctaLinkContactName}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default MobileMenu
