import React from "react"
import { Link } from "gatsby"
import Logo from "./../../../../data/img/logo.svg"
import DesktopDropdown from "./components/DesktopDropdown"
import MobileMenu from "./components/MobileMenu"

class HeaderMain extends React.Component {
  constructor(props) {
    super(props)
    this.state = { mobileMenu: false }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  toggleMenu() {
    this.setState({
      mobileMenu: !this.state.mobileMenu,
    })
  }

  render() {
    const svg = {
      shield: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="text-blue-500"
          style={{ minWidth: 24 }}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z"></path>
          <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3"></path>
        </svg>
      ),
      finance: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="text-blue-500"
          style={{ minWidth: 24 }}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <rect x="7" y="9" width="14" height="10" rx="2" />
          <circle cx="14" cy="14" r="2" />
          <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
        </svg>
      ),
      invest: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="text-blue-500 "
          style={{ minWidth: 24 }}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <polyline points="4 19 8 13 12 15 16 10 20 14 20 19 4 19" />
          <polyline points="4 12 7 8 11 10 16 4 20 2" />
        </svg>
      ),
      about: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="text-gray-700"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path d="M3 12h1M12 3v1M20 12h1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7" />
          <path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3" />
          <line x1="9.7" y1="17" x2="14.3" y2="17" />
        </svg>
      ),
    }

    const ctaLinks = {
      contact: {
        linkName: "Kontakt",
        link: "/kontakt/",
      },
      enquiry: {
        linkName: "Anfragen",
        link: "/anfragen/",
      },
    }

    const dropdownLinks = {
      col1: {
        mainLinks: [
          {
            linkName: "Berufsunfähigkeitsversicherung",
            linkDesc: "Schütze Deine Existenz",
            link: "/versicherungen/berufsunfaehigkeitsversicherung/",
            linkSvg: svg.shield,
          },
          {
            linkName: "Altersvorsorge",
            linkDesc: "Schließe Deine Altersvorsorge-Lücke.",
            link: "/versicherungen/altersvorsorge/",
            linkSvg: svg.shield,
          },
        ],
      },
      col2: {
        mainLinks: [
          {
            linkName: "Baufinanzierung",
            linkDesc: "Spare Geld und Zeit ",
            link: "/finanzierungen/baufinanzierung/",
            linkSvg: svg.finance,
          },
          {
            linkName: "Privatkredit",
            linkDesc:
              "Privatkredit mit Best-Zins finden: mit gemeinsamer Bereitstellung der Dokumente",
            link: "/finanzierungen/privatkredit/",
            linkSvg: svg.finance,
          },
        ],
      },
      col3: {
        mainLinks: [
          {
            linkName: "Allgemeine Geldanlage",
            linkDesc: "Vermehre Dein Geld",
            link: "/geldanlage/allgemeine-geldanlage/",
            linkSvg: svg.invest,
          },
        ],
      },
    }

    const footerLinks = dropdownLinks.col1.mainLinks
      .concat(dropdownLinks.col2.mainLinks)
      .concat(dropdownLinks.col3.mainLinks)

    return (
      <div className="relative bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="md:w-0 md:flex-1">
              <Link to="/">
                <img src={Logo} alt="Dierda Logo" className="h-7 w-auto" />
              </Link>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                onClick={this.toggleMenu}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10">
              <DesktopDropdown
                linkTitle="Versicherungen"
                mainLinks={dropdownLinks.col1.mainLinks}
                footerLinks={dropdownLinks.col1.footerLinks}
              />
              <DesktopDropdown
                linkTitle="Finanzierungen"
                mainLinks={dropdownLinks.col2.mainLinks}
                footerLinks={dropdownLinks.col2.footerLinks}
              />
              <DesktopDropdown
                linkTitle="Geldanlage"
                mainLinks={dropdownLinks.col3.mainLinks}
                footerLinks={dropdownLinks.col3.footerLinks}
              />
            </nav>
            <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 md:w-0">
              <Link
                to={ctaLinks.contact.link}
                className="whitespace-no-wrap text-base leading-6 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900"
              >
                {ctaLinks.contact.linkName}
              </Link>
              <span className="inline-flex rounded-md shadow-sm">
                <Link
                  to={ctaLinks.enquiry.link}
                  className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                >
                  {ctaLinks.enquiry.linkName}
                </Link>
              </span>
            </div>
          </div>
        </div>
        <MobileMenu
          mainLinks={footerLinks}
          ctaLinks={dropdownLinks.col1.mainLinks}
          toggleMenu={this.toggleMenu}
          state={this.state.mobileMenu}
          ctaLinkContact={ctaLinks.contact.link}
          ctaLinkContactName={ctaLinks.contact.linkName}
          ctaLinkEnquiry={ctaLinks.enquiry.link}
          ctaLinkEnquiryName={ctaLinks.enquiry.linkName}
        />
      </div>
    )
  }
}

export default HeaderMain
