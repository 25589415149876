import React from "react"
import SvgFeature from "../../../../common/SvgFeature"

const FooterFeatureSection = props => {
  const svg = {
    ssl: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="text-blue-500 inline"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M9 12l2 2l4 -4" />
        <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
      </svg>
    ),
    de: (
      <svg
        width="24"
        height="24"
        className="inline"
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
      >
        <defs>
          <circle id="a" cx="12" cy="12" r="12" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#D8D8D8" xlinkHref="#a" />
          <path fill="#000" mask="url(#b)" d="M-2 0h26v8H-2z" />
          <path fill="#F72032" mask="url(#b)" d="M-2 8h26v8H-2z" />
          <path fill="#F5B543" mask="url(#b)" d="M-1 16h26v8H-1z" />
        </g>
      </svg>
    ),
  }
  return (
    <div className={`flex flex-wrap ${props.className}`}>
      <div className="w-full md:w-6/12 lg:w-3/12 mb-6 lg:mb-0 md:mr-6">
        <SvgFeature
          svg={svg.ssl}
          title="Verschlüsselte Übertragung"
          text="Deine Daten werden per SSL-Verschlüsselung an uns übertragen."
        />
      </div>
      <div className="w-full md:w-6/12 lg:w-3/12">
        <SvgFeature
          svg={svg.de}
          title="Deutsches Rechenzentrum"
          text="Deine Anfrage-Daten werden in Nürnberg (Deutschland) verarbeitet."
        />
      </div>
    </div>
  )
}

export default FooterFeatureSection
